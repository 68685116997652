@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;900&display=swap");
@import "./style/styles.scss";
#root {
  background: #fff;
}
.App {
  text-align: center;
}
.practice-dyno #shell > #wideheader {
  border-bottom: none;
}
#shell > #wideheader {
  display: flex;
  position: sticky;
  top: 58px;
  background: #fff;
  border: none;
  z-index: 3;
}
.practice-dyno .submit-btn-pos #shell > #wideheader {
  top: 124px;
}
#shell > #main {
  margin-top: 0;
  min-height: auto;
}
#center > #editor {
  height: auto;
}
#center > #editor > .ProseMirror {
  height: auto;
  width: 100%;
}
.viewer #center > #editor {
  max-width: 1180px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // height: calc(100vh - 143px);
}
#shell > #main > aside {
  margin-top: 0;
}
main #shell {
  max-height: unset;
  min-height: unset;
}
#shell > #wideheader > span.h1 {
  display: none;
}

#shell > #wideheader {
  position: relative;
  bottom: unset;
  top: unset;
  border-bottom: none;
  border-top: none;
}
#shell > #wideheader .logo-flex,
#shell > #wideheader .print-container .print-btn {
  display: none;
}
#shell > footer {
  display: none;
}

#shell {
  font-family: "Open Sans", sans-serif;
}
#shell > #wideheader > #shareprevsave > .user-info > .user-initials,
#shell > #wideheader > #shareprevsave > #savebtn,
#shell > #wideheader > #shareprevsave > .user-info > .user-initials,
#shell > #wideheader > #shareprevsave > .submitbtn,
#shell > #wideheader > #shareprevsave > .points-container {
  display: none;
}

#shell .report-wrap {
  z-index: 3;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loading-text {
  font-size: 32px;
  font-family: "OpenSans", "Open Sans";
  font-weight: 600;
}
.dyno-loader {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
#shell > #wideheader > #submitbtn {
  display: block;
  border-radius: 21.5px;
  background-color: #1b6ec2;
  font-family: "Glober";
  font-size: 17px;
  font-weight: 600;
  color: #ffffff;
  margin: 0 auto;
  padding: 10px 35px;
  border: solid 1px transparent;
  transition-duration: 0.4s;
  text-transform: uppercase;
  line-height: unset;
  cursor: pointer;
}
.review-page #shell > #wideheader > #submitbtn {
  display: none;
}
.overlay-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(238, 238, 238, 0.7);
}
.practice-dyno,
.common-dyno {
  width: calc(100% - 400px);
}
.info-container {
  position: sticky;
  top: 64px;
  width: 400px;
  padding: 20px;
  display: flex;
  height: calc(100vh - 64px);
  align-items: center;
  justify-content: center;
  font-family: "Glober";
  font-weight: 600;
  background: rgba(0, 176, 255, 0.16);
}
.info-wrapper {
  border-radius: 15px;
  box-shadow: 4px 16px 24px 0 rgba(10, 94, 255, 0.12);
  // padding: 100px;
  background: #fff;
  border: 1px solid rgba(0, 176, 255, 0.6);
  width: 300px;
  padding: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#shell > #main > #document {
  background: #fff;
}
.time-elapsed,
.time-taken {
  font-size: 18px;
  color: #00b0ff;
}
.timer-container {
  border-radius: 50%;
  border: 1px solid #00b0ff;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timer-text {
  font-weight: 600;
  color: #d46d1b;
  font-size: 20px;
}
@media only screen and (max-width: 1024px) {
  .practice-dyno,
  .common-dyno {
    width: 100%;
  }
  .quiz-page,
  .review-page {
    flex-direction: column-reverse;
  }
  .info-container {
    position: relative;
    background: none;
    padding: 0;
    top: unset;
    width: 100%;
    height: unset;
  }
  .info-wrapper {
    border-radius: unset;
    box-shadow: none;
    background: #fff;
    border: unset;
    width: 100%;
    padding: 5px 20px 0;
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .time-elapsed,
  .time-taken {
    font-size: 16px;
  }
  .timer-container {
    border-radius: unset;
    border: unset;
    width: unset;
    height: unset;
    display: block;
  }
  .timer-text {
    font-size: 18px;
  }
}
